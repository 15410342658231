import React, { Component } from "react";
import { Link } from "react-router-dom";
import '../styles/Home.css';

export default class Eventos extends Component {

    constructor(props){
        super(props);

        this.state = {
            eventData: [],
            isLoaded: false,
            message: ""
        }
    }

    componentDidMount() {
        fetch(`https://v1.mistickets.com.mx/api/v2/vendedor/${localStorage.getItem("vendedorId")}/eventos`, {
            method: "GET"
        })
        .then(response => {
            if (response.ok) {
                response.json().then(
                    (json) => {
                        this.setState({
                            eventData: Object.entries(json.data),
                            isLoaded: true
                        });
                    },
                (error) => {
                    this.setState({
                        isLoaded: false,
                        message: error
                    });
                });
            }
        })
        .catch(error => console.error(error));
    }

    render() {
        const data = this.state.eventData.map((element) => {
            return(
            <div key={element[1].id} className="col-sm-12 col-md-12 col-lg-12">
                <div className="event-grid">
                    <div className="movie-thumb c-thumb">
                        <Link to={{ pathname: `/evento/${element[1].id}/` }} onClick={()=>{if(element[1].id === '36'){ alert('Promoci\xF3n 2x1 exclusivo en taquilla del estadio'); }}}>
                            <img src={'./assets/images_mt/eventos/home/' + element[1].website_imagen} alt={element[1].nombre} />
                        </Link>
                    </div>
                    <div className="movie-content bg-one">
                        <h5 className="title m-0">
                            <Link to={{ pathname: `/evento/${element[1].id}/` }} >{element[1].nombre}</Link>
                            <small><br />{element[1].horario_texto}<br />{element[1].foro_nombre}</small>
                        </h5>
                    </div>
                </div>
            </div>
            )
        });

        return (
            <section className="event-section" style={{paddingTop: "60px"}}>
                <div className="section-header-3">
                    <h2 className="title">Catálogo de Eventos</h2>
                </div>
                <div className="container" style={{maxWidth: "100%"}}>
                    <div className="row flex-wrap-reverse justify-content-center">
                        <div className="col-lg-12 mb-50 mb-lg-0">
                            <div className="filter-tab">
                                <div className="row mb-10 justify-content-center">
                                    {this.state.isLoaded ? data : <div></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
