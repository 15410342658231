import React, { Component } from 'react';
import Eventos from '../components/Eventos';

export default class EventosScreen extends Component {
  componentDidMount() {
      if (!localStorage.getItem("vendedorId") || localStorage.getItem("vendedorId") === "") {
          window.location.href = "/";
      }
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Eventos />
    )
  }
}