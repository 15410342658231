import React, { Component } from 'react';
import VentaCompletada from '../components/VentaCompletada';

class VentaCompletadaScreen extends Component {
  componentDidMount() {
    if (!localStorage.getItem("vendedorId") || localStorage.getItem("vendedorId") === "") {
        window.location.href = "/";
    }
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <VentaCompletada />
    )
  }
}
export default VentaCompletadaScreen