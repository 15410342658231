import React, { Component, Fragment } from 'react';
import '../styles/Home.css';

export default class VentasScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            eventsData: [],
            ventasData: [],
            showEvents: false,
            showVentas: false,
            ventaEventoId: 0,
        }

        this.selectVenta = this.selectVenta.bind(this);
        this.goToEvents = this.goToEvents.bind(this);
        this.confirmarVenta = this.confirmarVenta.bind(this);
        this.cancelarVenta = this.cancelarVenta.bind(this);
    }

    hideAll = () => {
        this.setState({
            showEvents: false,
            showVentas: false,
        })
    }

    goToEvents = (event) => {
        event.preventDefault();
        this.hideAll();
        this.setState({
            showEvents: true
        });
    }

    cancelarVenta = (event) => {
        fetch(`https://v1.mistickets.com.mx/api/v2/vendedor/${localStorage.getItem("vendedorId")}/ventas/${this.state.ventaEventoId}/cancelar/${event.target.id}`, {
            method: "GET"
        })
            .then(response => {
                if (response.ok) {
                    window.location.reload();
                }
            })
            .catch(error => console.error(error));
    }

    confirmarVenta = (event) => {
        fetch(`https://v1.mistickets.com.mx/api/v2/vendedor/${localStorage.getItem("vendedorId")}/ventas/${this.state.ventaEventoId}/confirmar/${event.target.id}`, {
            method: "GET"
        })
            .then(response => {
                if (response.ok) {
                    window.location.reload();
                }
            })
            .catch(error => console.error(error));
    }

    componentDidMount() {
        if (!localStorage.getItem("vendedorId") || localStorage.getItem("vendedorId") === "") {
            window.location.href = "/";
        }
        window.scrollTo(0, 0);

        fetch(`https://v1.mistickets.com.mx/api/v2/vendedor/${localStorage.getItem("vendedorId")}/eventos`, {
            method: "GET"
        })
            .then(response => {
                if (response.ok) {
                    response.json().then(
                        (json) => {
                            this.setState({
                                eventsData: Object.entries(json.data),
                                showEvents: true
                            });
                        },
                        (error) => {
                            this.setState({
                                showEvents: false,
                                errorMessage: error,
                            });
                        });
                }
            })
            .catch(error => console.error(error));
    }

    selectVenta = (event) => {
        event.preventDefault();
        const eventoId = event.target.id

        fetch(`https://v1.mistickets.com.mx/api/v2/vendedor/${localStorage.getItem("vendedorId")}/ventas/${eventoId}/list`, {
            method: "GET"
        })
            .then(response => {
                if (response.ok) {
                    response.json().then(
                        (json) => {
                            this.hideAll()
                            this.setState({
                                showVentas: true,
                                ventasData: json.data,
                                ventaEventoId: eventoId,
                            });
                        },
                        (error) => {
                            this.hideAll()
                            this.setState({
                                showVentas: false,
                                errorMessage: error,
                            });
                        });
                }
            })
            .catch(error => console.error(error));
    }

    render() {
        const events = this.state.eventsData.map(eventData => {
            const inicioVenta = new Date(eventData[1].fecha_inicio_venta) < new Date();
            return (
                <div key={eventData[1].id}>
                    <div className="event-grid mb-3">
                        <div className="movie-content bg-one d-flex justify-content-between px-3">
                            <h5 className="title m-0 py-3 border-0">
                                <a id={eventData[1].id} href='/ventas' className='venta-option button' onClick={this.selectVenta} style={{ pointerEvents: inicioVenta ? '' : 'none' }} >{eventData[1].nombre}</a>
                                <br /><small>{eventData[1].descripcion}</small>
                            </h5>
                            <div className="movie-rating-percent">
                                <span>{eventData[1].horario_texto}<br />{eventData[1].foro_nombre}</span>
                                {inicioVenta ? "" : <span><br />Venta a partir del: <br /> {eventData[1].fecha_inicio_venta_texto}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        const ventas = this.state.ventasData.map(ventaData => {
            const DIAS_AVISO = 1;

            const warningDate = new Date(Date.parse(ventaData.created_at));
            warningDate.setDate(warningDate.getDate() + DIAS_AVISO);

            const numBoletos = ventaData.boletos.split(", ").length;
            return (
                <div key={ventaData.id} className='movie-content venta bg-one container px-3 py-2 m-0'>
                    {/* <pre>{JSON.stringify(ventaData, null, 2)}</pre> */}
                    <div className='row px-1 align-items-center' style={{ textAlign: "center" }}>
                        <div className='col'> {ventaData.created_at} </div>
                        <div className='col'> {ventaData.nombre} </div>
                        <div className='col'> {ventaData.apellido} </div>
                        <div className='col'> {ventaData.telefono} </div>
                        <div className='col'> {ventaData.correo_electronico} </div>
                        <div className='col'> {
                            numBoletos === 1 ? '1 Boleto' : `${numBoletos} Boletos`
                        } </div>
                        <div className='col'> {
                            ventaData.status === "0" ?
                                warningDate < new Date() ? 'Pendiente' : 'Expirara en un Dia'
                                : ventaData.status === "1" ? 'Cancelado'
                                    : ventaData.status === "2" ? 'Confirmado'
                                        : 'Expirado'
                        } </div>
                        <div className='col acciones-ventas'>
                            {
                                ventaData.status === "0"
                                    ?
                                    <>
                                        <button id={ventaData.id} onClick={this.confirmarVenta} className='confirmar w-75'> Confirmar </button>
                                        <button id={ventaData.id} onClick={this.cancelarVenta} className='cancelar w-75'> Cancelar </button>
                                    </>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
            );
        });

        let contenido = (<></>);

        if (this.state.showEvents) {
            contenido = events;
        }

        if (this.state.showVentas) {
            contenido = (
                <div className='event-grid mb-3'>
                    <div className='row justify-content-end pr-3'>
                        <a href='/ventas' onClick={this.goToEvents} className='venta-option'>&lt;&lt; Eventos</a>
                    </div>
                    {ventas}
                </div>
            );
        }

        return (
            <Fragment>
                <div className="section-header-3 mb-3">
                    <h3>Listado de Ventas por Eventos</h3>
                </div>
                <div className='row mx-3 flex-column'>
                    {contenido}
                </div>
            </Fragment>
        );
    }
}
