import React, { Component } from "react";
import '../styles/Home.css';

export default class LoginScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            password: "",
            errorMessage: ""
        }

        this.nameChange = this.nameChange.bind(this);
        this.passwordChange = this.passwordChange.bind(this);

    }

    passwordChange = (event) => {
        this.setState({
            password: event.target.value
        });
    }

    nameChange = (event) => {
        this.setState({
            name: event.target.value
        });
    }

    componentDidMount() {
        if (localStorage.getItem("vendedorId") !== null) {
            window.location.href = '/eventos';
        }
    }

    onLogin = (event) => {
        event.preventDefault();

        const formdata = new FormData();
        formdata.append("usuario", this.state.name);
        formdata.append("password", this.state.password);

        fetch(`https://v1.mistickets.com.mx/api/v2/vendedor/login`, {
            method: "POST",
            body: formdata
        })
            .then(response => {
                if (response.ok) {
                    response.json().then(
                        (json) => {
                            if (json.data) {
                                localStorage.setItem("vendedorId", json.data);
                                window.location.href = '/eventos';
                            } else {
                                this.setState({
                                    errorMessage: json.mensaje
                                });
                            }
                        },
                        (error) => {
                            this.setState({
                                errorMessage: error
                            });
                        });
                } else {
                    this.setState({ errorMessage: response.statusText });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({ errorMessage: 'Failed to fetch' });
            });
    }

    render() {
        return (
            <section className="login-section" style={{ paddingTop: "60px" }}>
                <div className="section-header-3 m-0">
                    <h2 className="title">Login</h2>
                </div>
                <div className="container" style={{ maxWidth: "100%" }}>
                    <div className="row justify-content-center">
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <div className="event-grid bg-one p-2">
                                <div className="checkout-contact-form d-flex flex-column m-0 pt-2 px-2">
                                    <div className="form-group w-100">
                                        <input type="text" placeholder="Usuario" required="required" name="usuario_vendedor" value={this.state.name} onChange={this.nameChange} id="usuario_vendedor" />
                                    </div>
                                    <div className="form-group w-100">
                                        <input type="password" placeholder="Contraseña" required="required" name="contrasena_vendedor" value={this.state.password} onChange={this.passwordChange} id="contrasena_vendedor" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="hidden" name="token_id" id="token_id" />
                                <button className="custom-button" id="pay-button" onClick={this.onLogin}>Entrar</button>
                                <p className="notice mt-1">
                                    <span style={{ fontSize: "14px", color: "red", fontWeight: "bold" }}>{this.state.errorMessage}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
