import React, { Component } from 'react';
import Evento from '../components/Evento';

class EventoScreen extends Component {
  componentDidMount() {
    if (!localStorage.getItem("vendedorId") || localStorage.getItem("vendedorId") === "") {
        window.location.href = "/";
    }
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Evento />
    )
  }
}
export default EventoScreen