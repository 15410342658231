import React, { Component, Fragment } from 'react';
import '../styles/Footer.css';

export default class Footer extends Component {
    render() {
        return (
            <Fragment>
                {/* <!-- ==========Newslater-Section========== --> */}
                <footer className="footer-section">
                    <div className="container" style={{maxWidth: "100%"}}>
                    </div>
                </footer>
                {/* <!-- ==========Newslater-Section========== --> */}
            </Fragment >
        )
    }
}