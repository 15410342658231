import React, { Component } from "react";
import '../styles/Home.css';
import {withRouter} from 'react-router';
import Carrito from "./Carrito";
import Localidades from "./Localidades";
import Secciones from "./Secciones";
import Asientos from "./Asientos";
import SinAsientos from "./SinAsientos";
import Pago from "./Pago";

class Evento extends Component {
    constructor(props){
        super(props);

        this.state = {
            eventoId: this.props.match.params.eventoId,
            showPago: false,
            eventoData: [],
            resumenData: [],
            localidadesData: [],
            seccionesData: [],
            asientosData: [],
            localidadSelected: null,
            seccionSelected: null,
            boletos: "",
            imagenMapa: null
        }

        this.loadEvento = this.loadEvento.bind(this);
        this.loadLocalidades = this.loadLocalidades.bind(this);
        this.loadSecciones = this.loadSecciones.bind(this);
        this.loadBoletos = this.loadBoletos.bind(this);
        this.loadAsientos = this.loadAsientos.bind(this);
        this.loadResumen = this.loadResumen.bind(this);
        this.onLocalidadSelected = this.onLocalidadSelected.bind(this);
        this.onLocalidadHover = this.onLocalidadHover.bind(this);
        this.onSeccionSelected = this.onSeccionSelected.bind(this);
        this.onSeccionHover = this.onSeccionHover.bind(this);
        this.onRegresar = this.onRegresar.bind(this);
        this.onAddCantidad = this.onAddCantidad.bind(this);
        this.onToggleAsiento = this.onToggleAsiento.bind(this);
        this.onRemoveBoleto = this.onRemoveBoleto.bind(this);
        this.onVaciar = this.onVaciar.bind(this);
        this.onPagar = this.onPagar.bind(this);
    }

    loadEvento = () => {
        fetch(`https://v1.mistickets.com.mx/api/v2/vendedor/evento/${this.state.eventoId}`, {
            method: "GET"
        })
        .then(response => {
            if (response.ok) {
                response.json().then(
                    (json) => {
                        this.setState({
                            eventoData: json.data
                        }, () => {
                            const dateHorario = new Date(this.state.eventoData.horario);
                            const scriptTag = document.createElement("script");
                            scriptTag.innerText = "$(document).ready(function() {$('.countdown').countdown({date: '" + dateHorario.toLocaleString("en-US") + "',offset: -5,day: 'Día',days: 'Días'}, function () { alert('El evento ya inició!'); }); });";
                            scriptTag.async = false;
                            document.body.appendChild(scriptTag);
                            this.loadResumen();
                            this.loadLocalidades();
                        });
                    },
                    (error) => {
                        this.setState({
                            message: error
                        });
                    }
                );
            }
        })
        .catch(error => console.error(error));
    }

    loadLocalidades = () => {
        this.setState({ imagenMapa: this.state.eventoData.plantilla_mapa });
        fetch(`https://v1.mistickets.com.mx/api/v2/vendedor/${localStorage.getItem("vendedorId")}/localidades/${this.state.eventoId}`, {
            method: "GET"
        })
        .then(response => {
            if (response.ok) {
                response.json().then(
                    (json) => {
                        this.setState({
                            localidadesData: json.data
                        });
                        this.onLocalidadSelected(null);
                    },
                    (error) => {
                        this.setState({
                            message: error
                        });
                    }
                );
            }
        })
        .catch(error => console.error(error));
    }

    loadSecciones = () => {
        if (this.state.localidadSelected > 0) {
            fetch(`https://v1.mistickets.com.mx/api/v2/vendedor/${localStorage.getItem("vendedorId")}/secciones/${this.state.eventoId}/${this.state.localidadSelected}`, {
                method: "GET"
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(
                        (json) => {
                            this.setState({
                                seccionesData: json.data
                            }, () => {
                                if (this.state.seccionesData.length === 1)
                                {
                                    this.setState({
                                        seccionSelected: this.state.seccionesData[0].id
                                    }, () => {
                                        this.loadBoletos();
                                    });
                                }
                            });
                        },
                        (error) => {
                            this.setState({
                                message: error
                            });
                        }
                    );
                }
            })
            .catch(error => console.error(error));
        }
    }

    loadBoletos = () => {
        const {
            seccionesData,
            seccionSelected
        } = this.state;

        const esNumerado = seccionesData.find(element => element.id === parseInt(seccionSelected));
        if (esNumerado) this.loadAsientos();
    }

    loadAsientos = () => {
        const {
            seccionSelected,
            seccionesData,
            eventoId
        } = this.state;

        if (seccionSelected > 0 && seccionesData.find(element => element.id === parseInt(seccionSelected)).numerado === "1") {
            fetch(`https://v1.mistickets.com.mx/api/v2/vendedor/${localStorage.getItem("vendedorId")}/asientos/${eventoId}/${seccionSelected}`, {
                method: "GET"
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(
                        (json) => {
                            json.data.map((fila) => {
                                return fila.map((boleto) => {
                                    if (this.state.resumenData.find(element => element.id === boleto.id))
                                        boleto.status = "2";
                                    return null;
                                })
                            });
                            this.setState({
                                asientosData: json.data
                            });
                        },
                        (error) => {
                            this.setState({
                                message: error
                            });
                        }
                    );
                }
            })
            .catch(error => console.error(error));
        }
    }

    loadResumen = () => {
        fetch(`https://v1.mistickets.com.mx/api/v2/vendedor/${localStorage.getItem("vendedorId")}/ventas/${this.state.eventoId}/resumen`, {
            method: "GET"
        })
            .then(response => {
                if (response.ok) {
                    response.json().then(
                        (json) => {
                            this.setState({
                                resumenData: json.data
                            });

                            const boletos = [];
                            json.data.forEach(boleto => {
                                boletos.push(boleto.id);
                            })
                            
                            this.setState({
                                boletos: boletos.join(', ')
                            });
                        },
                        (error) => {
                            this.setState({
                                message: error
                            });
                        }
                    );
                }
            })
            .catch(error => console.error(error));
    }

    onLocalidadSelected = (localidadSelected) => {
        this.setState({
            localidadSelected,
        }, () => {
            if (localidadSelected)
                this.loadSecciones();
            else
                this.setState({
                    localidadSelected: null,
                    seccionSelected: null,
                    seccionesData: [],
                    asientosData: []
                });
        });
        this.onLocalidadHover(localidadSelected);
    }

    onLocalidadHover = (localidadSelected) => {
        var mapa = this.state.eventoData.plantilla_mapa;
        mapa = mapa.substring(0, mapa.indexOf(".")) + (localidadSelected ? "_" + localidadSelected : "") + mapa.substring(mapa.indexOf("."));
        this.setState({ imagenMapa: localidadSelected ? mapa : this.state.eventoData.plantilla_mapa });
    }

    onSeccionSelected = (seccionSelected) => {
        this.setState({
            seccionSelected
        }, () => {
            if (seccionSelected)
                this.loadBoletos();
            else {
                this.setState({
                    localidadSelected: null,
                    asientosData: []
                });
                this.onLocalidadHover(null);
            }
            this.onSeccionHover(seccionSelected);
        });
    }

    onSeccionHover = (seccionSelected) => {
        const {
            localidadSelected
        } = this.state;
        var mapa = this.state.eventoData.plantilla_mapa;
        mapa = mapa.substring(0, mapa.indexOf(".")) + (localidadSelected ? "_" + localidadSelected : "") + (seccionSelected ? "_" + seccionSelected : "") + mapa.substring(mapa.indexOf("."));
        var mapaLocalidad = this.state.eventoData.plantilla_mapa;
        mapaLocalidad = mapaLocalidad.substring(0, mapaLocalidad.indexOf(".")) + (localidadSelected ? "_" + localidadSelected : "") + mapaLocalidad.substring(mapaLocalidad.indexOf("."));
        this.setState({ imagenMapa: seccionSelected ? mapa : localidadSelected ? mapaLocalidad : this.state.eventoData.plantilla_mapa });
    }

    onRegresar = () => {
        if (this.state.seccionesData.length > 1)
            this.setState({
                seccionSelected: null,
                asientosData: []
            });
        else
            this.onLocalidadSelected(null);
    }

    onAddCantidad = (cantidadSelected) => {
        if (this.state.resumenData.length + parseInt(cantidadSelected) > 8) {
            alert('8 boletos máximo por compra');
            return;
        }

        if (cantidadSelected && cantidadSelected > 0) {
            fetch(`https://v1.mistickets.com.mx/api/v2/vendedor/${localStorage.getItem("vendedorId")}/ventas/${this.state.eventoId}/agregar/${this.state.seccionSelected}/${cantidadSelected}`, {
                method: "POST"
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(
                        (json) => {
                            this.onLocalidadSelected(null);
                            this.loadResumen();
                        },
                        (error) => {
                            this.setState({
                                message: error
                            });
                        }
                    );
                }
            })
            .catch(error => console.error(error));
        }
    }

    onToggleAsiento = (boleto) => {
        if (boleto.getAttribute("status") !== "0" && boleto.getAttribute("status") !== "2") return;

        const boletoId = boleto.getAttribute("id");
        const fila = boleto.getAttribute("fila");
        // const asiento = boleto.getAttribute("asiento");
        const agregarQuitar = boleto.getAttribute("status") !== "2";
        const url = agregarQuitar ? `https://v1.mistickets.com.mx/api/v2/vendedor/${localStorage.getItem("vendedorId")}/ventas/${this.state.eventoId}/agregar/${boletoId}`
            : `https://v1.mistickets.com.mx/api/v2/vendedor/${localStorage.getItem("vendedorId")}/ventas/${this.state.eventoId}/quitar/${boletoId}`;

        if (agregarQuitar && this.state.resumenData.length >= 8) {
            alert('8 boletos máximo por compra');
            return;
        }

        fetch(url, {
            method: "POST"
        })
        .then(response => {
            if (response.ok) {
                response.json().then(
                    (json) => {
                        const prevData = this.state.asientosData;
                        // prevData[fila][asiento].status = agregarQuitar ? "2" : "0";
                        prevData[fila].find(obj => { return obj.id === boletoId; }).status = agregarQuitar ? "2" : "0";
                        this.setState({ seatsData: prevData });
                        this.loadResumen();
                    },
                    (error) => {
                        this.setState({
                            message: error
                        });
                    }
                );
            }
        })
        .catch(error => console.error(error));
    }

    onRemoveBoleto = (boletoId) => {
        fetch(`https://v1.mistickets.com.mx/api/v2/vendedor/${localStorage.getItem("vendedorId")}/ventas/${this.state.eventoId}/quitar/${boletoId}`, {
            method: "POST"
        })
        .then(response => {
            if (response.ok) {
                response.json().then(
                    (json) => {
                        this.onLocalidadSelected(null);
                        this.loadResumen();
                    },
                    (error) => {
                        this.setState({
                            message: error
                        });
                    }
                );
            }
        })
        .catch(error => console.error(error));
    }

    onVaciar = () => {
        fetch(`https://v1.mistickets.com.mx/api/v2/vendedor/${localStorage.getItem("vendedorId")}/ventas/${this.state.eventoId}/vaciar`, {
            method: "POST"
        })
        .then(response => {
            if (response.ok) {
                response.json().then(
                    (json) => {
                        this.onLocalidadSelected(null);
                        this.loadResumen();
                    },
                    (error) => {
                        this.setState({
                            message: error
                        });
                    }
                );
            }
        })
        .catch(error => console.error(error));
    }

    onPagar = (mostrarPago = true) => {
        this.setState({ showPago: mostrarPago });
        this.onLocalidadSelected(null);
    }

    componentDidMount() {
        if (!localStorage.getItem("vendedorId") || localStorage.getItem("vendedorId") === "") {
            window.location.href = "/";
        }
        this.loadEvento();
    }

    render() {
        const {
            eventoData,
            showPago,
            resumenData,
            localidadesData,
            localidadSelected,
            seccionesData,
            seccionSelected,
            asientosData,
            imagenMapa
        } = this.state;

        const showLocalidades = localidadesData && localidadesData.length > 0 && !localidadSelected;
        const showSecciones = localidadSelected > 0 && seccionesData && seccionesData.length > 1 && !seccionSelected;
        const showAsientos = localidadSelected > 0 && seccionSelected > 0 && seccionesData.find(element => element.id === parseInt(seccionSelected)).numerado === "1" && asientosData && asientosData.length > 0;
        const showSinAsientos = localidadSelected > 0 && seccionSelected > 0 && seccionesData.find(element => element.id === parseInt(seccionSelected)).numerado === "0";

        return (
            <div>
                <section className="speaker-banner bg_img" data-background={'/assets/images_mt/eventos/banners/' + eventoData.website_imagen} style={{ backgroundImage: "url('/assets/images_mt/eventos/banners/" + eventoData.website_imagen + "')" }}>
                    <div className="container" style={{ maxWidth: "100%" }}>
                        <div className="speaker-banner-content">
                            <h3 className="title">{eventoData.nombre}</h3>
                            <div className="tags">{eventoData.descripcion}<br />{eventoData.ciudad_estado}</div>
                            <div className="social-and-duration">
                                <div className="duration-area">
                                    <div className="item">
                                        <i className="fas fa-calendar-alt" style={{ paddingRight: "10px" }}></i><span>{eventoData.horario_texto}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="tags"><span></span></div>
                            <div className="tags">
                                <ul className="justify-content-center countdown" style={{ paddingTop: "20px" }}>
                                    <li>
                                        <h2><span className="days">00</span></h2>
                                        <p className="days_text">días</p>
                                    </li>
                                    <li>
                                        <h2><span className="hours">00</span></h2>
                                        <p className="hours_text">hrs</p>
                                    </li>
                                    <li>
                                        <h2><span className="minutes">00</span></h2>
                                        <p className="minu_text">min</p>
                                    </li>
                                    <li>
                                        <h2><span className="seconds">00</span></h2>
                                        <p className="seco_text">seg</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="speaker-single padding-bottom pt-lg-0">
                    <div className="container" style={{ maxWidth: "100%" }}>
                        <div className="row">
                            <div className="col-sm-3">
                                <Carrito resumenData={resumenData} showPago={showPago} onRemoveBoleto={this.onRemoveBoleto} onVaciar={this.onVaciar} onPagar={this.onPagar} />
                            </div>
                            {(!showPago ?
                                <div className="bg-six col-sm-9">
                                    {(showLocalidades && <Localidades localidadesData={localidadesData} onLocalidadSelected={this.onLocalidadSelected} onLocalidadHover={this.onLocalidadHover} />)}
                                    {(showSecciones && <Secciones seccionesData={seccionesData} localidadNombre={localidadesData.find(element => element.id === parseInt(localidadSelected)).nombre} onSeccionSelected={this.onSeccionSelected} onSeccionHover={this.onSeccionHover} />)}
                                    {(showAsientos && <Asientos asientosData={asientosData} resumenData={resumenData} localidadNombre={localidadesData.find(element => element.id === parseInt(localidadSelected)).nombre} seccionNombre={seccionesData.find(element => element.id === parseInt(seccionSelected)).nombre} onToggleAsiento={this.onToggleAsiento} onRegresar={this.onRegresar} />)}
                                    {(showSinAsientos && <SinAsientos localidadNombre={localidadesData.find(element => element.id === parseInt(localidadSelected)).nombre} seccionNombre={seccionesData.find(element => element.id === parseInt(seccionSelected)).nombre} onAddCantidad={this.onAddCantidad} onRegresar={this.onRegresar} />)}
                                    <img className="col-sm-12" src={'/assets/images_mt/plantillas/' + imagenMapa} alt="" />
                                </div> :
                                <div className="col-sm-9"><Pago eventoId={this.state.eventoId} boletos={this.state.boletos} onSeguirComprando={this.onPagar} /></div>)}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default withRouter(Evento);