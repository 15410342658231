import React, { Component, Fragment } from "react";
import '../styles/Home.css';
import seat01 from '../resources/images/movie/seat01.png';
import seat01free from '../resources/images/movie/seat01-free.png';
import seat01booked from '../resources/images/movie/seat01-booked.png';
import noseat from '../resources/images/movie/no-seat.png';

export default class Asientos extends Component {

    constructor(props){
        super(props);

        this.onToggleAsiento = this.onToggleAsiento.bind(this);
    }

    onToggleAsiento = (e) => {
        this.props.onToggleAsiento(e.target);
    }

    imgAsiento = (status) => {
        switch(status){
            case "-1":
                return noseat;
            case "0":
                return seat01free;
            case "1":
                return seat01;
            case "2":
                return seat01booked;
            default:
                return seat01;
        }
    }

    render() {
        const data = this.props.asientosData.map((fila, i) => {
            return (
            <li className="seat-line" key={i} style={{justifyContent: "unset"}}>
                <span>{fila[0].fila_etiqueta}</span>
                <ul className="seat--area" key={i} style={{justifyContent: "space-between"}}>
                    <li className="front-seat">
                        <ul>
                            {fila.map((boleto, i2) => {
                                const etiqueta = boleto.fila_etiqueta + boleto.asiento_etiqueta;

                                if (boleto.status === "-2") return "";
                                return <li className="single-seat" key={"" + i + i2} style={{padding: "2px"}}>
                                            <img
                                                src={this.imgAsiento(boleto.status)}
                                                alt={etiqueta}
                                                title={etiqueta}
                                                id={boleto.id}
                                                asiento_etiqueta={boleto.asiento_etiqueta}
                                                asiento={boleto.asiento}
                                                fila={boleto.fila}
                                                fila_etiqueta={boleto.fila_etiqueta}
                                                status={boleto.status}
                                                onClick={this.onToggleAsiento}
                                                width="30px"
                                                />
                                            <span className="sit-num" style={{fontSize: "10px"}}>{boleto.status === "0" ? etiqueta : ""}</span>
                                        </li>
                            })}
                        </ul>
                    </li>
                </ul>
            </li>)
        });

        return(
            <Fragment>
                <section className="seat-plan-section" style={{padding: "30px 0px"}}>
                    <div className="container" style={{maxWidth: "100%"}}>
                        <div>
                            <div className="section-header-1">
                                <h5 className="title">{this.props.localidadNombre}<span className="view-all"> &gt; </span>{this.props.seccionNombre} {(this.props.seccionNombre === "Sala") && " 8 pax + 2 botellas premium"}</h5>
                                <a href="#0" onClick={this.props.onRegresar} style={{color: "#31d7a9"}}><div style={{WebkitTransform: "rorate(180deg)", transform: "rotate(180deg)", float: "left", paddingLeft: "10px"}}><i className="flaticon-double-right-arrows-angles"></i></div>regresar</a>
                            </div>
                            <h6 style={{color: "#31d7a9", textTransform: "uppercase", marginBottom: "15px"}}>Selecciona tus asientos</h6>
                            <div className="screen-area">
                                {/* <h4 className="screen" style={{marginBottom: "20px"}}>Frente</h4> */}
                                <div className="screen-wrapper" style={{overflowX: "scroll"}}>
                                    <ul className="seat-area" style={{marginBottom: "revert"}}>
                                        {data}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}