import React, { Component } from 'react';

export default class Pago extends Component {
    constructor(props){
        super(props);
        this.form = React.createRef();

        this.state = {
            cartData: [],
            errorMessage: "",
            name: "",
            lastname: "",
            phone: "",
            email: "",
            confEmail: "",
            checkBox: false,
            token_id: "",
            urlPago: "",
            redirect: null
        };

        this.nameChange = this.nameChange.bind(this);
        this.lastnameChange = this.lastnameChange.bind(this);
        this.phoneChange = this.phoneChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.confEmailChange = this.confEmailChange.bind(this);
        this.checkBoxChange = this.checkBoxChange.bind(this);
        this.onPaymentClick = this.onPaymentClick.bind(this);
        this.sucess_callbak = this.sucess_callbak.bind(this);
        this.onSeguirComprando = this.onSeguirComprando.bind(this);
        this.urlChange = this.urlChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            eventId: nextProps.eventId
        });
    }

    componentDidMount() {
    }

    urlChange = (event) => {
        this.setState({
            urlPago: event.target.value
        });
    }

    nameChange = (event) => {
        this.setState({
            name: event.target.value
        });
    }

    lastnameChange = (event) => {
        this.setState({
            lastname: event.target.value
        });
    }

    phoneChange = (event) => {
        this.setState({
            phone: event.target.value
        });
    }

    emailChange = (event) => {
        this.setState({
            email: event.target.value
        });
    }

    confEmailChange = (event) => {
        this.setState({
            confEmail: event.target.value
        });
    }

    checkBoxChange = (event) => {
        this.setState({
            checkBox: event.target.checked
        })
    }

    onPaymentClick = (event) => {
        event.preventDefault();
        if(this.state.email !== "" && RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/).test(this.state.email))
            if(this.state.email === this.state.confEmail)
                if(this.state.name !== "" && this.state.lastname !== "")
                    if(this.state.phone !== "" && RegExp(/\d{10}|\d{11}|\d{12}|\d{13}|\d{14}/).test(this.state.phone)) {
                        this.sucess_callbak()
                    }
                    else
                        this.setState({ errorMessage: 'El n\xFAmero de tel\xE9fono debe ser de 10 a 14 d\xEDgitos.'});
                else
                    this.setState({ errorMessage: 'Indique el nombre y apellidos del contacto.'});
            else
                this.setState({ errorMessage: 'No coincide el correo elect\xF3nico con la confimaci\xF3n.'})
        else
            this.setState({ errorMessage: 'El correo elect\xF3nico no es v\xE1lido.'});
    }

    sucess_callbak = () => {
        const formData = new FormData(this.form.current);
        const str = [];
        formData.forEach(function(value, key){
            str.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
        });

        fetch(`https://v1.mistickets.com.mx/api/v2/vendedor/${localStorage.getItem("vendedorId")}/ventas/${this.props.eventoId}/venta?${str.join("&")}`, {
            method: "GET"
        })
        .then(response => {
            if (response.ok) {
                window.location.reload();
            }
            if (response.status === 500) {
                this.setState({
                    disablePagoButton: false,
                    errorMessage: "Error en registro de venta"
                });
            }
        })
        .catch(error => console.error(error));
    };

    onSeguirComprando = () => {
        this.props.onSeguirComprando(false);
    }

    render() {
        return (
            <form ref={this.form} acceptCharset="UTF-8" id="payment-form">
                <div className="checkout-widget checkout-contact">
                    <div className="section-header-1">
                        <h5 className="title" style={{marginBottom: "0px", paddingBottom: "0px"}}>Información de Contacto</h5>
                    </div>
                    <div className="checkout-contact-form">
                        <div className="form-group">
                            <input type="text" placeholder="Nombre" required="required" name="nombre_cliente" value={this.state.name} onChange={this.nameChange} id="nombre_cliente" />
                        </div>
                        <div className="form-group">
                            <input type="text" placeholder="Apellidos" required="required" name="apellido_cliente" value={this.state.lastname} onChange={this.lastnameChange} id="apellido_cliente" />
                        </div>
                        <div className="form-group">
                            <input type="email" placeholder="Correo electrónico" required="required" name="correo_electronico" value={this.state.email} onChange={this.emailChange} id="correo_electronico" />
                        </div>
                        <div className="form-group">
                            <input type="email" placeholder="Confirma tu correo electrónico" required="required" name="correo_electronico" value={this.state.confEmail} onChange={this.confEmailChange} id="confcorreo_electronico" onPaste={(e)=>{e.preventDefault(); return false;}} />
                        </div>
                        <div className="form-group">
                            <input type="text" placeholder="Teléfono" required="required" maxLength="14" name="telefono_cliente" value={this.state.phone} onChange={this.phoneChange} id="telefono_cliente" />
                        </div>
                        <div className="form-group">
                            <input type="text" placeholder="Link" required="required" name="url_pago" value={this.state.urlPago} onChange={this.urlChange} id="url_pago" />
                        </div>
                        <div className="form-group">
                            <input type='hidden' value={this.props.boletos} id='boletos' name='boletos' />
                            <button className="custom-button" id="pay-button" onClick={this.onPaymentClick}>Registrar Venta</button>
                            <p className="notice mt-1">
                                <span style={{fontSize: "14px", color: "red", fontWeight: "bold"}}>{this.state.errorMessage}</span>
                            </p>
                            <a href="#0" onClick={this.onSeguirComprando} style={{color: "#31d7a9", paddingTop: "10px"}}><div style={{WebkitTransform: "rorate(180deg)", transform: "rotate(180deg)", float: "left", paddingLeft: "10px"}}><i className="flaticon-double-right-arrows-angles"></i></div>Seguir comprando</a>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}