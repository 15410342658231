import React, { Component } from 'react';
import '../styles/Header.css';

export default class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            current: this.props.current,
            logged: false
        }
    }

    render() {
            return (
                <div className='mb-5'>
                    {/* <!-- ==========Preloader========== --> */}
                    <div className="preloader">
                        <div className="preloader-inner">
                            <div className="preloader-icon">
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    {/* <!-- ==========Preloader========== --> */}
                    {/* <!-- ==========Overlay========== --> */}
                    <div className="overlay"></div>
                    <a href="#0" className="scrollToTop">
                        <i className="fas fa-angle-up"></i>
                    </a>
                    {/* <!-- ==========Overlay========== --> */}
                    {/* <!-- ==========Header-Section========== --> */}
                    <header className="header-section">
                        <div className="container" style={{maxWidth: "100%"}}>
                            <div className="header-wrapper">
                                <div className="logo">
                                    <a href="/">
                                        <img src="/assets/images/logo/logo.png" alt="Mis Tickets" />
                                    </a>
                                </div>
                                <ul className="menu">
                                    <li>
                                        <a href="/eventos" className='mx-1'>Eventos</a>
                                        <a href="/ventas" className='mx-1'>Ventas</a>
                                    </li>
                                </ul>
                                <div className="header-bar d-lg-none">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* <!-- ==========Header-Section========== --> */}
                </div>
            );
    }
}